import { APICore } from './apiCore';
const api = new APICore();

// testimonials
function testimonials(params: {
    status: string,
    search: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/testimonials`;
    return api.get(`${baseUrl}`, params);

}
function addTestimonials(params: {
    name: string,
    designation: string,
    company: string,
    image: string,
    content: string,
    status: string,
}) {
    const baseUrl = `/testimonials`;
    return api.create(`${baseUrl}`, params);
}

function updateTestimonials(params: {
    status: string,
    name: string,
    designation: string,
    company: string,
    image: string,
    content: string
}, id: string) {
    const baseUrl = `/testimonials/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteTestimonials(id: string) {
    const baseUrl = `/testimonials/${id}`;
    return api.delete(`${baseUrl}`);
}

function showTestimonials(params: {}, id: string) {
    const baseUrl = `/testimonials/${id}`;
    return api.get(`${baseUrl}`, params);
}

export {
    testimonials,
    addTestimonials,
    updateTestimonials,
    deleteTestimonials,
    showTestimonials
};
