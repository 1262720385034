import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import UserSaga from './user/saga';
import ExperienceCenterSaga from './experienceCenter/saga';
import DigitalShadeCardsSaga from './digitalShadeCard/saga';
import PrivateDigitalShadeCardSaga from './privateDigitalShadeCard/saga';
import JobTitlesSaga from './career/jobTitles/saga';
import JobSubmissionsSaga from './career/jobSubmissions/saga';
import InvestorRelationSaga from './investorRelation/saga';
import ContactUsSubmissionsSaga from './contactUsSubmissions/saga';
import ChatSubmissionsSaga from './chatSubmissions/saga';
import CreateYourOwnSaga from './createYourOwn/saga';
import NewsAndEventSaga from './newsAndEvent/saga';
import TestimonialsSaga from './testimonials/saga';
import ChatFormSetupSaga from './chatFormSetup/saga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        layoutSaga(),
        UserSaga(),
        ExperienceCenterSaga(),
        DigitalShadeCardsSaga(),
        PrivateDigitalShadeCardSaga(),
        JobTitlesSaga(),
        JobSubmissionsSaga(),
        InvestorRelationSaga(),
        ContactUsSubmissionsSaga(),
        ChatSubmissionsSaga(),
        CreateYourOwnSaga(),
        NewsAndEventSaga(),
        TestimonialsSaga(),
        ChatFormSetupSaga(),
    ]);
}
