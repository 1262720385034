// constants
import { TestimonialsActionTypes } from './constants';

export interface TestimonialsActionType {
    type:
    | TestimonialsActionTypes.API_RESPONSE_SUCCESS
    | TestimonialsActionTypes.API_RESPONSE_ERROR
    | TestimonialsActionTypes.TESTIMONIALS_LIST
    | TestimonialsActionTypes.ADD_TESTIMONIALS
    | TestimonialsActionTypes.UPDATE_TESTIMONIALS
    | TestimonialsActionTypes.SHOW_TESTIMONIALS
    | TestimonialsActionTypes.DELETE_TESTIMONIALS
    payload: {} | string;
}

interface FiltersData {
    status: string,
    sortBy: string,
    limit: number,
    page: number,
    search: string
};

interface FormData {
    status: string,
    id: string,
    name: string,
    designation: string,
    company: string,
    image: string,
    content: string
};


interface ResponseData {
    actionType: string,
    responseData: any,
}


// common success
export const testimonialsApiResponseSuccess = ({ actionType, responseData }: ResponseData): TestimonialsActionType => ({
    type: TestimonialsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, responseData },
});
// common error
export const testimonialsApiResponseError = (actionType: string, error: string): TestimonialsActionType => ({
    type: TestimonialsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createTestimonials = ({
    name,
    designation,
    company,
    image,
    content,
    status
}: FormData): TestimonialsActionType => ({
    type: TestimonialsActionTypes.ADD_TESTIMONIALS,
    payload: {
        name,
        designation,
        company,
        image,
        content,
        status
    },
});


export const testimonialsList = ({
    status,
    sortBy,
    limit,
    page,
    search,
}: FiltersData): TestimonialsActionType => ({
    type: TestimonialsActionTypes.TESTIMONIALS_LIST,
    payload: {
        search,
        status,
        sortBy,
        limit,
        page,
    },
});

export const showTestimonials = (id: string): TestimonialsActionType => ({
    type: TestimonialsActionTypes.SHOW_TESTIMONIALS,
    payload: { id },
});

export const destroyTestimonials = (id: string): TestimonialsActionType => ({
    type: TestimonialsActionTypes.DELETE_TESTIMONIALS,
    payload: { id },
});

export const updateTestimonials = ({
    id,
    name,
    designation,
    company,
    image,
    content,
    status
}: FormData): TestimonialsActionType => ({
    type: TestimonialsActionTypes.UPDATE_TESTIMONIALS,
    payload: {
        id,
        name,
        designation,
        company,
        image,
        content,
        status
    },
});



