import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    testimonials as testimonialsApi,
    addTestimonials as addTestimonialsApi,
    updateTestimonials as updateTestimonialsApi,
    showTestimonials as showTestimonialsApi,
    deleteTestimonials as deleteTestimonialsApi,
} from '../../helpers';

// actions
import { testimonialsApiResponseSuccess, testimonialsApiResponseError } from './actions';

// constants
import { TestimonialsActionTypes } from './constants';

interface TestimonialsData {
    payload: {
        search: string;
        description: string;
        sortBy: string,
        limit: number,
        page: number,
        //
        id: string,
        status: string,
        name: string,
        designation: string,
        company: string,
        image: string,
        content: string
    };
    type: string;
}

/**
 * Testimonials list
 */
function* testimonialsList({ payload: {
    search,
    status,
    sortBy,
    limit,
    page
} }: TestimonialsData): SagaIterator {
    try {

        const response = yield call(testimonialsApi, {
            search,
            status,
            sortBy,
            limit,
            page
        });
        const responseData = {
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        yield put(testimonialsApiResponseSuccess({
            actionType: TestimonialsActionTypes.TESTIMONIALS_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(testimonialsApiResponseError(TestimonialsActionTypes.TESTIMONIALS_LIST, error));

    }
}

/**
 * Add Testimonials
 */
function* addTestimonials({ payload: {
    name,
    designation,
    company,
    image,
    content,
    status } }: TestimonialsData): SagaIterator {
    try {

        const response = yield call(addTestimonialsApi, {
            name,
            designation,
            company,
            image,
            content,
            status
        });
        const responseData = {
            data: response.data.data,
        }
        yield put(testimonialsApiResponseSuccess({
            actionType: TestimonialsActionTypes.ADD_TESTIMONIALS,
            responseData
        }

        ));
    } catch (error: any) {
        yield put(testimonialsApiResponseError(TestimonialsActionTypes.ADD_TESTIMONIALS, error));

    }
}

/**
 * Show User
 */
function* showTestimonials({ payload: {
    id,
} }: TestimonialsData): SagaIterator {
    try {
        const response = yield call(showTestimonialsApi, {}, id);
        const responseData = {
            data: response.data,
        }
        yield put(testimonialsApiResponseSuccess({
            actionType: TestimonialsActionTypes.SHOW_TESTIMONIALS,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(testimonialsApiResponseError(TestimonialsActionTypes.SHOW_TESTIMONIALS, error));

    }
}

/**
 * Update Testimonials
 */
function* updateTestimonials({ payload: {
    id,
    status,
    name,
    designation,
    company,
    image,
    content } }: TestimonialsData): SagaIterator {
    try {

        const response = yield call(updateTestimonialsApi, {
            status,
            name,
            designation,
            company,
            image,
            content,
        }, id);
        const responseData = {
            data: response.data.data,
        }
        yield put(testimonialsApiResponseSuccess({
            actionType: TestimonialsActionTypes.UPDATE_TESTIMONIALS,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(testimonialsApiResponseError(TestimonialsActionTypes.UPDATE_TESTIMONIALS, error));

    }
}
/**
 * Delete Testimonials
 */
function* deleteTestimonials({ payload: { id } }: TestimonialsData): SagaIterator {
    try {
        yield call(deleteTestimonialsApi, id);
        const responseData = {
            deletedId: id
        };
        yield put(testimonialsApiResponseSuccess({
            actionType: TestimonialsActionTypes.DELETE_TESTIMONIALS,
            responseData
        }));
    } catch (error: any) {
        yield put(testimonialsApiResponseError(TestimonialsActionTypes.DELETE_TESTIMONIALS, error));

    }
}



export function* watchTestimonialsList() {
    yield takeEvery(TestimonialsActionTypes.TESTIMONIALS_LIST, testimonialsList);
}

export function* watchAddTestimonials() {
    yield takeEvery(TestimonialsActionTypes.ADD_TESTIMONIALS, addTestimonials);
}

export function* watchUpdateTestimonials() {
    yield takeEvery(TestimonialsActionTypes.UPDATE_TESTIMONIALS, updateTestimonials);
}
export function* watchShowTestimonials() {
    yield takeEvery(TestimonialsActionTypes.SHOW_TESTIMONIALS, showTestimonials);
}

export function* watchDeleteTestimonials() {
    yield takeEvery(TestimonialsActionTypes.DELETE_TESTIMONIALS, deleteTestimonials);
}



function* testimonialsSaga() {
    yield all([fork(watchTestimonialsList), fork(watchAddTestimonials), fork(watchShowTestimonials), fork(watchUpdateTestimonials), fork(watchDeleteTestimonials)]);
}

export default testimonialsSaga;
