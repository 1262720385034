import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import User from './user/reducers';
import ExperienceCenter from './experienceCenter/reducers';
import DigitalShadeCard from './digitalShadeCard/reducers';
import PrivateDigitalShadeCard from './privateDigitalShadeCard/reducers';
import JobTitles from './career/jobTitles/reducers';
import JobSubmissions from './career/jobSubmissions/reducers';
import InvestorRelation from './investorRelation/reducers';
import ContactUsSubmissions from './contactUsSubmissions/reducers';
import ChatSubmissions from './chatSubmissions/reducers';
import CreateYourOwn from './createYourOwn/reducers';
import NewsAndEvent from './newsAndEvent/reducers';
import Testimonials from './testimonials/reducers';
import ChatFormSetup from './chatFormSetup/reducers';

export default combineReducers({
    Auth,
    Layout,
    User,
    ExperienceCenter,
    DigitalShadeCard,
    PrivateDigitalShadeCard,
    JobTitles,
    JobSubmissions,
    InvestorRelation,
    ContactUsSubmissions,
    ChatSubmissions,
    CreateYourOwn,
    NewsAndEvent,
    Testimonials,
    ChatFormSetup,
});
